// NavigationMenu.js
import React, { useEffect, useState } from "react";
import "./navigation.css";

function NavigationMenu() {
  const [isSearchOpen, setSearchOpen] = useState(false);
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isSticky, setIsSticky] = useState(false);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleSearch = () => {
    setSearchOpen(!isSearchOpen);
  };

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const closeSidebar = () => {
    setSidebarOpen(false);
  };

  const isMobile = window.innerWidth <= 890; // Adjust this breakpoint as needed

  return (
    <div className="App" style={{ position: isSticky ? "sticky" : "absolute" , backgroundColor: isSticky? "white":"transparent"}}>
      <div className="nav-main" style={{border:isSticky?'1px solid black':'none'}} >
        <header className="navbar">
          <div className="navbar-left">
            <div className="navbar-icons">
              <span>f</span>
              <span>T</span>
              <span>twi</span>
            </div>
            {isMobile && (
              <button onClick={toggleSidebar} className="mobile-sidebar-button">
                <span>Toggle Sidebar</span>
              </button>
            )}
          </div>
          {!isMobile && (
            <div className="navbar-middle"  >
              <div className="nav-item">
                <ul>
                  <li className="nav-button" style={{color:isSticky?"black":"white"}}>HOME</li>
                  <li className="nav-button" style={{color:isSticky?"black":"white"}}>ABOUT US</li>
                  <li className="nav-button" style={{color:isSticky?"black":"white"}}>SERVICES</li>
                  <span className="nav-company-name" style={{color:isSticky?"black":"white"}}>TVL</span>
                  <li className="nav-button" style={{color:isSticky?"black":"white"}}>CASE STUDIES</li>
                  <li className="nav-button" style={{color:isSticky?"black":"white"}}>NEWS</li>
                  <li className="nav-button" style={{color:isSticky?"black":"white"}}>CONTACT</li>
                </ul>
              </div>
            </div>
          )}

          <div className="navbar-right">
            {isSearchOpen && (
              <div className="search-input">
                <input type="text" placeholder="Search..." />
              </div>
            )}
            <button onClick={toggleSearch}>
              <span>Search</span>
            </button>
          </div>
        </header>
      </div>

      {isSidebarOpen && (
        <div className="sidebar">
          {/* Add a button to close the sidebar */}
          <button onClick={closeSidebar} className="close-sidebar-button">
            <span>Close Sidebar</span>
          </button>
          <nav>
            <ul>
              <li>Navigation 1</li>
              <li>Navigation 2</li>
              <li>Navigation 3</li>
            </ul>
          </nav>
        </div>
      )}
      {/* Your main content goes here */}
    </div>
  );
}

export default NavigationMenu;
