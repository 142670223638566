import Footer from "./Footer"
import Header from "./header"
import NavigationMenu from "./navigation"


const Layout =({children})=>{
    return(
        <div>
            <NavigationMenu />
            {/* <Header /> */}
                {children}
                <Footer />
        </div>
    )
}

export default Layout