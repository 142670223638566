import "./footer.css";

const Footer = () => {
  return (
    <>
      <div className="footer-main">
        <div className="foooter-item">
          <strong style={{ color: "black" }}>Karachi Office</strong>
          <br />
          555-1292
          <br />
          info@lawly.com
          <br />
          <br />
          123 Main Street, Suit 200 City:
          <br /> ANytown State: StateName
        </div>
        <div className="foooter-item">
          <strong style={{ color: "black" }}>Karachi Office</strong>
          <br />
          555-1292
          <br />
          info@lawly.com
          <br />
          <br />
          123 Main Street, Suit 200 City:
          <br /> ANytown State: StateName
        </div>
        <div className="foooter-item">
          <strong style={{ color: "black" }}>Karachi Office</strong>
          <br />
          555-1292
          <br />
          info@lawly.com
          <br />
          <br />
          123 Main Street, Suit 200 City:
          <br /> ANytown State: StateName
        </div>
      </div>
    </>
  );
};

export default Footer;
