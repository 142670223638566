import React, { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import homeImage1 from "../assets/img/homeImage1.jpg";
import homeImage2 from "../assets/img/homeImage2.jpg";
import homeImage3 from "../assets/img/homeImage3.jpg";
import homeImage4 from "../assets/img/homeImage4.jpg";
import about1 from "../assets/img/about1.jpg";
import about2 from "../assets/img/about2.jpg";
import about3 from "../assets/img/about3.jpg";
import "./home.css";
import Layout from "./layout";
import { TypeAnimation } from "react-type-animation";
import { useInView } from "react-intersection-observer";
import slider1 from "../assets/img/slider1.jpg";
import slider2 from "../assets/img/slider2.jpg";
import slider3 from "../assets/img/slider3.jpg";
import slider4 from "../assets/img/slider4.jpg";

import carousel2im1 from "../assets/img/carsouel2 image 2.jpg";
import carousel2im2 from "../assets/img/carsousel2 image1.jpg";
const Home = () => {
  const images = [homeImage1, homeImage2, homeImage3, homeImage4];
  const [zoomedIndex, setZoomedIndex] = useState(0);
  const [selectedTab, setSelectedTab] = useState("History");
  const [activeIndex, setActiveIndex] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      // Increment the zoomedIndex to move to the next image
      setZoomedIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000); // Adjust the interval as needed (in milliseconds)

    return () => clearInterval(interval);
  }, [images.length]);

  const slidesData = [
    {
      image: homeImage1,
      text: "In Pursuit of <br/> Fairness and <br /> Equality",
      details:
        "<strong>Common Law and Civil Law:</strong> Legal systems can be broadly categorized into common law and civil law systems.",
    },
    {
      image: homeImage2,
      text: "Where Law <br /> Meets <br />Liberty",
      details:
        "<strong>Administrative Law:</strong> Administrative law focuses on the regulations and decisions made by government agencies.",
    },
    {
      image: homeImage3,
      text: "Navigating <br /> Legal Waters<br /> with Expertise",
      details:
        "<strong>Legislation:</strong> Laws are often created through legislation, which is the process of drafting, passing, and enacting rules.",
    },
    {
      image: homeImage4,
      text: "Advocating <br /> Justice ,<br /> Defending<br /> Rights",
      details:
        "<strong>Criminal Law:</strong> This branch of law deals with offenses against the state or society, such as murder, theft, fraud, and assault.",
    },
  ];

  const [ref, inView] = useInView({
    triggerOnce: true, // This will trigger the event only once
    threshold: 0.2, // Adjust this threshold as needed
  });

  useEffect(() => {
    if (inView) {
      console.log("Element is in view!");
    }
  }, [inView]);

  const handleSlideChange = (index) => {
    setActiveIndex(index);
  };

  return (
    <Layout>
      <div className="home-container">
        {/* Image Slideshow */}
        <div className="image-container">
          <Carousel
            showThumbs={false}
            showStatus={false}
            infiniteLoop
            autoPlay={true}
            // animationHandler={"fade"}
            selectedItem={activeIndex}
            onChange={handleSlideChange}
            interval={4000}
            >
            {slidesData.map((image, index) => (
              <>
                <div
                  key={index}
                  className={`image-item ${
                    activeIndex === index ? "zoom" : ""
                  }`}
                  style={{
                    backgroundImage: `url(${image.image})`,
                  }}
                ></div>
                {/* redender this part on every index change */}
                <>
                  {activeIndex === index && (
                    <>
                      <span className="attachment"></span>
                      <h1
                        className={`image-text `}
                        dangerouslySetInnerHTML={{
                          __html: slidesData[index].text,
                        }}
                      ></h1>
                      <p
                        className={`image-border-text`}
                        dangerouslySetInnerHTML={{
                          __html: slidesData[index].details,
                        }}
                      ></p>
                    </>
                  )}
                </>
              </>
            ))}
          </Carousel>
        </div>
      </div>
      <div className="our-services-container">
        
        <span className="services" ref={ref}>
          <strong>
            {true && (
              <TypeAnimation
                cursor={{ hideWhenDone: true }}
                sequence={["Our Services\nIn Law"]}
                wrapper="div"
                wrapperClassName="sentence-animation"
                sequenceClassName="sentence"
              />
            )}
          </strong>
        </span>
      </div>
      <div className="elevating-container">
        <div className="elevating">
          <div>
            <h4>Elevating Service Standards in Law</h4>
          </div>
          <div>All Services &#10148;</div>
        </div>
        <div className="table-container">
          <div className="custom-table">
            <div className="table-cell">➕ Legal Consultation</div>
            <div className="table-cell">➕ Criminal Defense</div>
            <div className="table-cell">➕ Immigration Law</div>
            <div className="table-cell">➕ Contract Drafting and Review</div>
            <div className="table-cell">➕ Family Laws</div>
            <div className="table-cell">➕ Employment Law</div>
            <div className="table-cell">
              ➕ Litigation and Dispute Resolution
            </div>
            <div className="table-cell">➕ Intellectual Property</div>
            <div className="table-cell">➕ Real Estate Law</div>
          </div>
        </div>
      </div>
      <div className="ev-text-main">
        <div className="ev-text">
          <p>90%</p>

          <span>Case Evaluation</span>
        </div>
        <div className="ev-text">
          <p>95%</p>
          <span>legal Resrarch</span>
        </div>
        <div className="ev-text">
          <p>100%</p>
          <span>Negotiation</span>
        </div>
      </div>
      <div>
        <div className="law-services">
          <marquee scrollamount="10">
            <p>
              Personal Injury&nbsp; Civil Rights &nbsp;Environmental Law
              &nbsp;Tax Law Personal Injury&nbsp; Civil Rights
              &nbsp;Environmental Law &nbsp;Tax Law
            </p>
          </marquee>
        </div>
      </div>

      <div className="our-services-container">
        <span className="services" ref={ref}>
          <strong>
            {inView && (
              <TypeAnimation
                cursor={{ hideWhenDone: true }}
                sequence={["About TVL"]}
                wrapper="div"
                wrapperClassName="sentence-animation"
                sequenceClassName="sentence"
              />
            )}
          </strong>
        </span>
      </div>
      <div>
        <div className="elevating">
          <div>
            <h4>Discover Our Journey in Law</h4>
          </div>
          <div>About Us &#10148;</div>
        </div>
      </div>

      <div className="about-main">
        <div className="about-gallery">
          <img src={about1} className="image image1" alt="Image 1" />
          <img src={about2} className="image image2" alt="Image 2" />
          <img src={about3} className="image image3" alt="Image 3" />
        </div>

        <div className="about-tabs">
          <button
            className={selectedTab === "History" ? "active-tab" : ""}
            onClick={() => setSelectedTab("History")}
          >
            History
          </button>
          <button
            className={selectedTab === "Mission" ? "active-tab" : ""}
            onClick={() => setSelectedTab("Mission")}
          >
            Mission
          </button>
          <button
            className={selectedTab === "Vision" ? "active-tab" : ""}
            onClick={() => setSelectedTab("Vision")}
          >
            Vision
          </button>
          <div className="about-content">
            {selectedTab === "History" && (
              <>
                <p>
                  At Lawly, we are more than just legal experts – we are
                  dedicated partners on your journey towards justice and
                  resolution. With a legacy of excellence and a commitment to
                  upholding the principles of law, we stand as a beacon of
                  support for individuals, businesses, and communities alike.
                </p>
                <div className="strategy-progress">
                  <div className="strategy-text">Strategy</div>
                  <div className="strategy-text">95%</div>
                </div>
                <div className="progress-bar-95"></div>

                <div className="strategy-progress">
                  <div className="strategy-text">Case Assesment</div>
                  <div className="strategy-text">85%</div>
                </div>
                <div className="progress-bar-85"></div>
                <div className="strategy-progress">
                  <div className="strategy-text">Document Prepration</div>
                  <div className="strategy-text">75%</div>
                </div>
                <div className="progress-bar-75"></div>
              </>
            )}
            {selectedTab === "Mission" && (
              <>
                <p>
                  Founded on the belief that everyone deserves fair and
                  equitable treatment under the law, Lawly strives to provide
                  unparalleled legal representation and guidance. Our mission is
                  clear: to empower and protect our clients while navigating the
                  intricate pathways of the legal landscape.
                </p>
                <div className="strategy-progress">
                  <div className="strategy-text">Strategy</div>
                  <div className="strategy-text">95%</div>
                </div>
                <div className="progress-bar-95"></div>

                <div className="strategy-progress">
                  <div className="strategy-text">Case Assesment</div>
                  <div className="strategy-text">85%</div>
                </div>
                <div className="progress-bar-85"></div>
                <div className="strategy-progress">
                  <div className="strategy-text">Document Prepration</div>
                  <div className="strategy-text">75%</div>
                </div>
                <div className="progress-bar-75"></div>
              </>
            )}
            {selectedTab === "Vision" && (
              <>
                <p>
                  At Lawly, our vision is to reshape the landscape of legal
                  services through innovation, empathy, and unwavering
                  dedication to our clients. We envision a future where justice
                  is accessible to all, where legal complexities are simplified,
                  and where the law becomes a tool for positive change in
                  people's lives.
                </p>
                <div className="strategy-progress">
                  <div className="strategy-text">Strategy</div>
                  <div className="strategy-text">95%</div>
                </div>
                <div className="progress-bar-95"></div>

                <div className="strategy-progress">
                  <div className="strategy-text">Case Assesment</div>
                  <div className="strategy-text">85%</div>
                </div>
                <div className="progress-bar-85"></div>
                <div className="strategy-progress">
                  <div className="strategy-text">Document Prepration</div>
                  <div className="strategy-text">75%</div>
                </div>
                <div className="progress-bar-75"></div>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="slider">
        <h1>THE SLIDER COMPONETN</h1>
        {/* slider1 , slider2 slider3 slider4 */}
      </div>

      <div className="our-services-container">
        <span className="services" ref={ref}>
          <strong>
            {inView && (
              <TypeAnimation
                cursor={{ hideWhenDone: true }}
                sequence={["Case Studies"]}
                wrapper="div"
                wrapperClassName="sentence-animation"
                sequenceClassName="sentence"
              />
            )}
          </strong>
        </span>
      </div>
      <div>
        <div className="elevating">
          <div>
            <h4>Projects that Define Legal Excellence</h4>
          </div>
          <div>Latest Projects &#10148;</div>
        </div>
      </div>
      <div className="carousel-container">
        <Carousel showThumbs={false} showStatus={false} infiniteLoop autoPlay>
          <div className="carousel-item item1">
            <span>LEGAL WORKSHOP</span>
          </div>
          <div className="carousel-item item2">
            <span>Labour Rights</span>
          </div>
          <div className="carousel-item item3">
            <span>Human Rights</span>
          </div>
        </Carousel>
      </div>

      <div className="our-services-container">
        <span className="services" ref={ref}>
          <strong>
            {inView && (
              <TypeAnimation
                cursor={{ hideWhenDone: true }}
                sequence={["Updates in Law"]}
                wrapper="div"
                wrapperClassName="sentence-animation"
                sequenceClassName="sentence"
              />
            )}
          </strong>
        </span>
      </div>
      <div>
        <div className="elevating">
          <div>
            <h4>Legal Pulse: News That Beats with the Heart of Justice</h4>
          </div>
          <div>Latest News &#10148;</div>
        </div>
      </div>

      <div>
        <h1>THE PULSE BORX</h1>
      </div>

      <div className="our-services-container">
        <span className="services" ref={ref}>
          <strong>The Faces Of Justice</strong>
        </span>
      </div>
      <div>
        <div className="elevating">
          <div>
            <h4>Our Attorney, Your Legal Guardians</h4>
          </div>
        </div>
      </div>

      <div>
        <h1>LAWYERS IMAGES</h1>
      </div>

      <div className="our-services-container">
        <span className="services" ref={ref}>
          <strong>Contact Details</strong>
        </span>
      </div>
      <div>
        <div className="elevating">
          <div>
            <h4>Navigate Legal Matters, Contact Us</h4>
          </div>
          <div>Contact Us &#10148;</div>
        </div>
      </div>

      <div>
        <div className="contact-services">
          <span>
            <h1>Have amproject in mind?</h1>
            <h4>Do not hesitate to say Hello</h4>
            <button>let's Talk</button>
          </span>
        </div>
      </div>
    </Layout>
  );
};

export default Home;
